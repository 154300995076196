import moment from "moment";

export const SET_ALL_NEWS = (state, news) => {

    state.pagination = news
    state.allNews = news.data;

}

export const SET_CREATED_NEWS = (state, news) => {

    state.createdNews = news.data;

}

export const SET_NEWS = (state, news) => {

    news.data.published_at = moment(

        String(news.data.published_at)

    ).format("YYYY-MM-DD HH:mm:ss");

    state.showNews = news.data;
}

export const SET_UPDATED_NEWS = (state, news) => {

    state.updatedNews = news.data;

}

export const DELETE_NEWS = (state, id) => {

    state.allNews = state.allNews.filter(item => {

        return item.id != id;

    })
}

export const SET_DELETE_NEWS_ID = (state, id) => {

    state.deleteId = id;

}

export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}