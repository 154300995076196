import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {

 /**
     * All message list
     * @returns object
     */
  async list(pagination, searchQuery) {

    return Api().get("api/v1/messages?type=sdgs"
    + "&desc=updated_at&page="
    + `${pagination.pageNo}`
    + "&per="
    + `${CONST.PAGINATION.page_count}`
    + searchQuery);
    },

/**
 * Show the currect message
 * @returns object
 */
    async show(id) {

        return Api().get("api/v1/messages/" + `${id}`);

    },

    /**
 * delete the currect message
 * @returns boolean
 */
    async delete(id) {

        return Api().delete("api/v1/messages/" + `${id}`);

    },

    async filter(pagination, status) {

        return Api().get("api/v1/messages?type=sdgs"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + `${status}`);
    
    },

    async search(pagination, searchTerms) {

        console.log(searchTerms);
    
        return Api().get("api/v1/messages?type=sdgs"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + searchTerms
        );
    
    },
    async inpuSearch(pagination, searchTerms) {

        return Api().get("api/v1/messages?type=sdgs"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + "&keyword=title@"
            + `${searchTerms}`
        );
    
    }
    
};