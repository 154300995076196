

export const SET_ALL_DAILY = (state, daily) => {

    state.allDaily = daily;

}
export const SET_PAGINATION = (state, idea) => {

    state.pagination = idea;
}


export const SET_DAILY = (state, daily) => {

    state.showDaily = daily.data;

}

export const SET_DELETE_DAILY_ID = (state, id) => {

    state.dailyDeleteId = id;

}

export const DELETE_NEWS = (state, id) => {

    state.allDaily = state.allDaily.filter(item => {

        return item.id != id;

    })
}

export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}