export const SET_LATEST_CREATED_FILE = (state, file) => {

    state.createdFiles.push(file.data);

}

export const SET_FILE = (state, file) => {

    state.viewFile = file.data;

}
export const SET_PICTURE = (state, file) => {

    console.log(file);
    state.picture = file.data;

}


export const SET_LATEST_DELETED_FILE = (state, file) => {

    state.deletedFile = file.data;

}

export const SET_LATEST_UPDATED_FILE = (state, file) => {

    state.updatedFile = file.data;

}

export const SET_FILE_STOREAGE = (state, file) => {

    state.fileStoreage = file.data;

}





























// export const SET_LATEST_DELETED_FILE = (state, file) => {
//     state.deletedFile = file.data;
// }


