import $ from "jquery";
import Challenge from "../../apis/Challenge";
import router from '../../router/index'
import moment from "moment";
import User from "../../apis/User";


export const getAllChallenge = ({ commit, dispatch, rootState }, { pageParam, type }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    Challenge.list(pageParam, rootState.challenge.searchQuery).then(async response => {

        console.log(response.data.data); 
        var allChallenge;
        if (response != '') {
            
            console.log(response.data.data);
            
            allChallenge = response.data.data;

            for (const challenge of allChallenge) {

                if (challenge.target_ids != null) {

                    challenge.target_user = [];

                    for (const targetId of challenge.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await challenge.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_CHALLENGE', allChallenge);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    }).catch(() => { })

        .finally(() => {
            dispatch('common/setSpinnerStatus', false, { root: true })

        });

}

export const viewChallenge = ({ commit, dispatch, rootState }, id) => {


    dispatch('common/setSpinnerStatus', true, { root: true })

    return Challenge.show(id).then(async response => {

        var challenge = response.data.data;

        if (challenge.target_ids != null) {

            challenge.target_user = [];

            for (const targetId of challenge.target_ids) {


                await User.show(targetId).then(async response => {

                    if (response != '') {

                        await challenge.target_user.push(response.data.data);

                    }

                });

            }

        }

        commit('SET_CHALLENGE',challenge);

    })

        .catch(() => { })

        .finally(() => {
            dispatch('common/setSpinnerStatus', false, { root: true })
        });
}


export const deleteChallenge = ({ commit, dispatch, rootState }, id) => {


    dispatch('common/setSpinnerStatus', true, { root: true });


    return Challenge.delete(id).then(

        (response) => {

            if (response != '') {

                commit('DELETE_CHALLENGE', id);

                $(".bulktool").removeClass("open");

                $(".command_wrap_inbox").removeClass("hide");

                $(".modal").removeClass("open");


                return dispatch('common/setSucccessMessageStatus', {

                    status: true,
                    message: "サンクスが正常に削除されました。",

                }, { root: true });

            }
        },

        (error) => {

            console.log(error);
        }
    )

        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {

    commit('SET_DELETE_CHALLENGE_ID', id);

}


export const setFileterStatus = ({ commit, dispatch, rootState }, { papeParam, status }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    let searchStatus = "";

    if (status != "")

        searchStatus = "&where=status@" + `${status}`;

    commit('SET_SEARCH_QUERY', searchStatus);


    return Challenge.filter(papeParam, searchStatus).then(async response => {


        var allChallenge;

        if (response != '') {

            allChallenge = response.data.data;

            for (const challenge of allChallenge) {

                if (challenge.target_ids != null) {

                    challenge.target_user = [];

                    for (const targetId of challenge.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await challenge.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_CHALLENGE', allChallenge);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const getSearchData = ({ commit, dispatch, rootState }, { papeParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    let statusQuery = "";

    if (searchTerms.status != "")

        statusQuery = "&where=status@" + `${searchTerms.status}`;

    let dateQuery = "";

    if (searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@gte," + `${searchTerms.published_start}T00:00:00`;

    }

    if (searchTerms.published_end != "") {

        dateQuery = "&datetime=created_at@lte," + `${searchTerms.published_end}T23:59:59`;

    }

    if (searchTerms.published_end != "" && searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@between," + `${searchTerms.published_start}T00:00:00/` + `${searchTerms.published_end}T23:59:59`;

    }

    commit('SET_SEARCH_QUERY', statusQuery + dateQuery);


    return Challenge.search(papeParam, statusQuery + dateQuery).then(async response => {


        var allChallenge;

        if (response != '') {

            allChallenge = response.data.data;

            for (const challenge of allChallenge) {

                if (challenge.target_ids != null) {

                    challenge.target_user = [];

                    for (const targetId of challenge.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await challenge.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_CHALLENGE', allChallenge);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const inputSearchData = ({ commit, dispatch, rootState }, { pageParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&keyword=title@" + `${searchTerms}`);

    return Challenge.inpuSearch(pageParam, searchTerms).then(async response => {


        var allChallenge;

        if (response != '') {

            allChallenge = response.data.data;

            for (const challenge of allChallenge) {

                if (challenge.target_ids != null) {

                    challenge.target_user = [];

                    for (const targetId of challenge.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await challenge.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_CHALLENGE', allChallenge);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}