export const SET_ALL_SCHEDULES = (state, schedules) => {
  state.allSchedules = schedules.data;
};

export const DELETE_SCHEDULE = (state, id) => {
  state.allSchedules = state.allSchedules.filter((item) => {
    return item.id != id;
  });
};

export const SET_ALL_SCHEDULE_LIST = (state, schedules) => {
  return state.allSchedules.push(schedules.data);
};
