import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {

    /**
     * All message list
     * @returns object
     */
    list(pagination, searchQuery) {

        return Api().get("api/v1/messages?type=challenge"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + searchQuery);

    },

    /**
     * Show the currect message
     * @returns object
     */
    show(id) {

        return Api().get("api/v1/messages/" + `${id}`);

    },


    /**
     * delete the currect message
     * @returns boolean
     */
    delete(id) {

        return Api().delete("api/v1/messages/" + `${id}`);

    },

    filter(pagination, status) {

        return Api().get("api/v1/messages?type=challenge"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + status);

    },

    search(pagination, searchTerms) {

        return Api().get("api/v1/messages?type=challenge"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + searchTerms
        );

    },

    inpuSearch(pagination, searchTerms) {

        return Api().get("api/v1/messages?type=challenge"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + "&keyword=title@"
            + `${searchTerms}`
        );

    }

};