import $ from "jquery";
import moment from "moment";
import User from "../../apis/admin_User";
import router from "../../router/index";
import File from "../../apis/File";
import Group from "../../apis/Groups";

export const userRegister = (
  { commit, dispatch, rootState },
  { user, file }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  var validationError = {
    email: false,
    first_name_kana: false,
    last_name_kana: false,
    user_code: false,
  };

  return User.register(user)
    .then(
      async (response) => {
        if (response) {
          let createduser = response.data;

          if (user.group != "") {
            await Group.insertGroupUser(user.group, {
              user_id: createduser.data.id,
              role: user.role,
              rank: user.rank,
              here_date: user.here_date,
              leaving_date: null,
            }).then(
              async (response) => {
                if (response) {
                  console.log(response);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }

          if (
            file.has("uploadfile") &&
            file.has("key") &&
            file.has("display_name")
          ) {
            await dispatch(
              "file/uploadProfilePicture",
              {
                file: file,
                id: response.data.data.id,
              },
              { root: true }
            );
          }

          commit("SET_USER", response.data);

          dispatch(
            "common/setSucccessMessageStatus",
            {
              status: true,
              message: "ユーザー が正常に作成されました。",
            },
            { root: true }
          );
          return router.push({
            name: "User Show",
            params: { id: response.data.data.id },
          });
        }
      },
      (error) => {
        if (error.response.data.errors.email) {
          validationError.email = true;
        }
        if (error.response.data.errors.first_name_kana) {
          validationError.first_name_kana = true;
        }
        if (error.response.data.errors.last_name_kana) {
          validationError.last_name_kana = true;
        }
        if (error.response.data.errors.user_code) {
          validationError.user_code = true;
        }

        commit("SET_VALIDATION_ERROR", validationError);
      }
    )
    .catch(() => {})
    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const login = ({ commit, dispatch, rootState }, userCretancial) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return User.login(userCretancial)
    .then(
      (response) => {
        if (response) {
          commit("SET_LOGIN_USER", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getUser = async ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return await User.show(id)
    .then(
      async (response) => {
        if (response) {
          await commit("SET_VIEW_USER", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const userUpdate = (
  { commit, dispatch, rootState },
  {
    user,
    userId,
    file,
    fileId,
    updateOrCreate,
    isBeforeGroupId,
    updateGroupId,
    isRemovePicture,
  }
) => {
  var validationError = {
    email: false,
    first_name_kana: false,
    last_name_kana: false,
    user_code: false,
  };

  dispatch("common/setSpinnerStatus", true, { root: true });

  return User.update(user, userId)
    .then(
      async (response) => {
        if (response) {
          if (isBeforeGroupId != "" && isBeforeGroupId != null) {
            await Group.updateGroupUser(isBeforeGroupId.id, userId, {
              user_id: userId,
              rank: user.rank,
              role: user.role,
              here_date: user.here_date,
              leaving_date: moment(new Date()).format("YYYY-MM-DD"),
            });
            await Group.deleteGroupUser(isBeforeGroupId.id, userId);
          }
          if (updateGroupId != "" && updateGroupId != null) {
            await Group.insertGroupUser(updateGroupId, {
              user_id: userId,
              rank: user.rank,
              role: user.role,
              here_date: user.here_date,
              leaving_date: null,
            });
          }
          if (isRemovePicture && fileId != "") {
            await dispatch(
              "file/deleteProfilePicture",
              {
                modelId: userId,
                id: fileId,
              },
              { root: true }
            );
          }
          if (file != "") {
            if (
              file.has("uploadfile") &&
              file.has("key") &&
              file.has("display_name")
            ) {
              if (updateOrCreate) {
                await dispatch(
                  "file/updateProfilePicture",
                  {
                    modelId: userId,
                    id: fileId,
                    file: file,
                    isDelete: true,
                  },
                  { root: true }
                );
              } else {
                await dispatch(
                  "file/uploadProfilePicture",
                  {
                    file: file,
                    id: userId,
                  },
                  { root: true }
                );
              }
            }
          }
          commit("SET_USER", response.data);
          dispatch(
            "common/setSucccessMessageStatus",
            {
              status: true,
              message: "ユーザーが正常に更新されました。",
            },
            { root: true }
          );
          router.push({
            name: "User Show",
            params: { id: userId },
          });
        }
      },
      (error) => {
        if (error.response.data.errors.email) {
          validationError.email = true;
        }
        if (error.response.data.errors.first_name_kana) {
          validationError.first_name_kana = true;
        }
        if (error.response.data.errors.last_name_kana) {
          validationError.last_name_kana = true;
        }
        if (error.response.data.errors.user_code) {
          validationError.user_code = true;
        }

        commit("SET_VALIDATION_ERROR", validationError);
      }
    )
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getAllUsers = ({ commit, dispatch, rootState }, { pageParam }) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return User.list(pageParam, rootState.user.searchQuery)
    .then(async (response) => {
      if (response != "") {
        var users = response.data.data;
        if (users != null) {
          for (const user of users) {
            dispatch("common/setSpinnerStatus", true, { root: true });

            user.base64 = "";
            await File.getPicture("users", user.id).then(async (response) => {
              if (response.data.data.length > 0) {
                user.base64 = response.data.data[0].base64
                  ? response.data.data[0].base64
                  : "";
              }
            });
          }
        }
        dispatch("common/setSpinnerStatus", false, { root: true });

        commit("SET_ALL_USERS", response.data);
        commit("SET_PAGINATION", response.data);
        rootState.common.pagination = response.data;
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getSearchData = (
  { commit, dispatch, rootState },
  { pageParam, searchTerms }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  let statusQuery = "";

  statusQuery = "&where=is_admin@" + `${searchTerms.is_admin}`;

  if (searchTerms.is_admin != "") {
    statusQuery = "";
  }

  if (searchTerms.group != "") {
    let groupUser = {
      data: [],
    };
    Group.getGroupById(searchTerms.group)
      .then(async (resp) => {
        if (resp != "") {
          groupUser.data = [];

          for (const user of resp.data.data.group_users) {
            if (user.leaving_date == null) {
              await User.show(user.user_id).then(async (user) => {
                if (user.data.result) {
                  if (
                    (searchTerms.is_admin === true ||
                      searchTerms.is_admin === false) &&
                    searchTerms.is_admin !== ""
                  ) {
                    if (user.data.data.is_admin === searchTerms.is_admin) {
                      groupUser.data.push(user.data.data);
                    }
                  } else {
                    groupUser.data.push(user.data.data);
                  }
                }
              });
            }
          }

          groupUser.data = groupUser.data.filter((item) => {
            return (
              item.email.match(searchTerms.keyword.trim()) ||
              item.user_code.match(searchTerms.keyword.trim()) ||
              item.first_name.match(searchTerms.keyword.trim()) ||
              item.last_name.match(searchTerms.keyword.trim())
            );
          });

          commit("SET_ALL_USERS", groupUser);

          commit("SET_PAGINATION", groupUser);

          rootState.common.pagination = groupUser;
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch("common/setSpinnerStatus", false, { root: true });
      });

    commit("SET_SEARCH_QUERY", statusQuery);
  } else {
    statusQuery = "";
    if (
      (searchTerms.is_admin === true || searchTerms.is_admin === false) &&
      searchTerms.is_admin !== ""
    ) {
      statusQuery = "&where=is_admin@" + `${searchTerms.is_admin}`;
    }

    let keyWordSearch = "";
    if (searchTerms.keyword.trim() !== "") {
      keyWordSearch =
        "&keyword=last_name,first_name,user_code,email,phone@" +
        `${searchTerms.keyword.trim()}`;
    }

    User.search(pageParam, statusQuery + keyWordSearch)
      .then((response) => {
        if (response != "") {
          commit("SET_ALL_USERS", response.data);

          commit("SET_PAGINATION", response.data);

          rootState.common.pagination = response.data;
        }
      })
      .catch(() => {})

      .finally(() => {
        dispatch("common/setSpinnerStatus", false, { root: true });
      });
    commit("SET_SEARCH_QUERY", statusQuery + keyWordSearch);
  }
};

export const inputSearchData = (
  { commit, dispatch, rootState },
  { pageParam, searchTerms }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  commit(
    "SET_SEARCH_QUERY",
    "keyword=last_name,first_name,user_code,email,phone@" + `${searchTerms}`
  );

  return User.inpuSearch(pageParam, searchTerms)
    .then((response) => {
      if (response != "") {
        commit("SET_ALL_USERS", response.data);

        commit("SET_PAGINATION", response.data);

        rootState.common.pagination = response.data;
      }
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const deleteUser = ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  for (let index = 0; index < id.length; index++) {
    dispatch("common/setSpinnerStatus", true, { root: true });

    User.delete(id[index])
      .then(async (response) => {
        if (response != "") {
          await commit("DELETE_USER", id[index]);

          $(".bulktool").removeClass("open");

          $(".command_wrap_inbox").removeClass("hide");

          dispatch(
            "common/setSucccessMessageStatus",
            {
              status: true,
              message: "施設スタッフが正常に削除されました。",
            },
            { root: true }
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        return dispatch("common/setSpinnerStatus", false, { root: true });
      });
  }

  $(".modal").removeClass("open");

  dispatch(
    "common/setSucccessMessageStatus",
    {
      status: true,
      message: "施設スタッフが正常に削除されました。",
    },
    { root: true }
  );
};

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {
  commit("SET_DELETE_USER_ID", id);
};

export const getGroups = async ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return await Group.list()
    .then(
      async (response) => {
        if (response) {
          await commit("SET_GROUPS", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getRole = async ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return await Group.list()
    .then(
      async (response) => {
        if (response) {
          await commit("SET_ROLE", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};
