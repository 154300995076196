import $ from "jquery";
import Thanks from "../../apis/Thanks";
import User from "../../apis/User";


export const getAllThanks = ({ commit, dispatch, rootState }, { pageParam, type }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    var allThanks;

    return Thanks.list(pageParam, rootState.thanks.searchQuery).then(async response => {

        if (response != '') {

            allThanks = response.data.data;

            for (const thanks of allThanks) {

                if (thanks.target_ids != null) {

                    thanks.target_user = [];

                    for (const targetId of thanks.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await thanks.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_THANKS', allThanks);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    }).catch((error) => {
    }).finally(() => {
        dispatch('common/setSpinnerStatus', false, { root: true })

    });

}

export const viewThanks = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    return Thanks.show(id).then(async response => {

        
        var thanks = response.data.data;

        if (thanks.target_ids != null) {

            thanks.target_user = [];

            for (const targetId of thanks.target_ids) {


                await User.show(targetId).then(async response => {

                    if (response != '') {

                        await thanks.target_user.push(response.data.data);

                    }

                });

            }

        }

        commit('SET_THANKS',thanks);


    })

        .catch(() => { })

        .finally(() => {
            dispatch('common/setSpinnerStatus', false, { root: true })
        });
}

export const deleteThanks = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true });


    return Thanks.delete(id).then(

        (response) => {

            if (response != '') {

                commit('DELETE_THANKS', id);

                $(".bulktool").removeClass("open");

                $(".command_wrap_inbox").removeClass("hide");

                $(".modal").removeClass("open");


                return dispatch('common/setSucccessMessageStatus', {

                    status: true,
                    message: "サンクスカードが正常に削除されました。",

                }, { root: true });

            }
        },

        (error) => {

            console.log(error);
        }
    )

        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {

    commit('SET_DELETE_TANKS_ID', id);

}

export const setFileterStatus = ({ commit, dispatch, rootState }, { papeParam, status }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&where=status@" + `${status}`);

    return Thanks.filter(papeParam, status).then(async response => {

        var allThanks;

        if (response != '') {

            allThanks = response.data.data;

            for (const thanks of allThanks) {

                if (thanks.target_ids != null) {

                    thanks.target_user = [];

                    for (const targetId of thanks.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await thanks.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_THANKS', allThanks);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })
        .catch(() => { })

        .finally(() => {

            setTimeout(() => {

                dispatch('common/setSpinnerStatus', false, { root: true })

            }, 10000);
        });

}

export const getSearchData = ({ commit, dispatch, rootState }, { papeParam, searchTerms }) => {


    dispatch('common/setSpinnerStatus', true, { root: true })

    let statusQuery = "&where=status@" + `${searchTerms.status}`;

    let dateQuery = "";

    if (searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@gte," + `${searchTerms.published_start}T00:00:00`;

    }

    if (searchTerms.published_end != "") {

        dateQuery = "&datetime=created_at@lte," + `${searchTerms.published_end}T23:59:59`;

    }

    if (searchTerms.published_end != "" && searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@between," + `${searchTerms.published_start}T00:00:00/` + `${searchTerms.published_end}T23:59:59`;

    }

    commit('SET_SEARCH_QUERY', statusQuery + dateQuery);


    return Thanks.search(papeParam, statusQuery + dateQuery).then(async response => {


        var allThanks;

        if (response != '') {

            allThanks = response.data.data;

            for (const thanks of allThanks) {

                if (thanks.target_ids != null) {

                    thanks.target_user = [];

                    for (const targetId of thanks.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await thanks.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_THANKS', allThanks);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })
        .catch(() => { })

        .finally(() => {

            dispatch('common/setSpinnerStatus', false, { root: true })

        });

}

export const inputSearchData = ({ commit, dispatch, rootState }, { pageParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&keyword=title,content@" + `${searchTerms}`);

    return Thanks.inpuSearch(pageParam, searchTerms).then(async response => {

        var allThanks;

        if (response != '') {

            allThanks = response.data.data;

            for (const thanks of allThanks) {

                if (thanks.target_ids != null) {

                    thanks.target_user = [];

                    for (const targetId of thanks.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await thanks.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_THANKS', allThanks);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {
            dispatch('common/setSpinnerStatus', false, { root: true })

        });

}

