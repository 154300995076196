import $ from "jquery";
import Daily from "../../apis/Daily";
import User from "../../apis/User";
import daily from "../../const/control_panel/daily";


export const getAllDaily = ({ commit, dispatch, rootState }, { pageParam, type }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    var allDaily;
    return Daily.list(pageParam, rootState.daily.searchQuery).then(async response => {

        if (response != '') {

            allDaily = response.data.data;

            for (const daily of allDaily) {

                if (daily.target_ids != null) {

                    daily.target_user = [];

                    for (const targetId of daily.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await daily.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_DAILY', allDaily);
    

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })
      .catch((error) => {


        })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const viewDaily = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    return Daily.show(id).then(response => {

        commit('SET_DAILY', response.data);

    })

        .catch(() => { })

        .finally(() => {
            dispatch('common/setSpinnerStatus', false, { root: true })
        });
}


export const deleteDaily = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true });


    return Daily.delete(id).then(

        (response) => {

            if (response != '') {

                commit('DELETE_NEWS', id);

                $(".bulktool").removeClass("open");

                $(".command_wrap_inbox").removeClass("hide");

                $(".modal").removeClass("open");


                return dispatch('common/setSucccessMessageStatus', {

                    status: true,
                    message: "気づき日報が正常に削除されました。",

                }, { root: true });

            }
        },

        (error) => {

            console.log(error);
        }
    )

        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {

    commit('SET_DELETE_DAILY_ID', id);

}


export const setFileterStatus = ({ commit, dispatch, rootState }, { papeParam, status }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })


    let searchStatus = "";

    if (status != "")

        searchStatus = "&where=status@" + `${status}`;

    commit('SET_SEARCH_QUERY', searchStatus);

    return Daily.filter(papeParam, searchStatus).then(async response => {
       
                  
        var allDaily;

        if (response != '') {
    
            allDaily = response.data.data;

            for (const daily of allDaily) {

                if (daily.target_ids != null) {

                    daily.target_user = [];

                    for (const targetId of daily.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await daily.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_DAILY', allDaily);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })


     .catch(() => { })

        .finally(() => {

            setTimeout(() => {

                dispatch('common/setSpinnerStatus', false, { root: true })

            }, 10000);
        });

}

export const getSearchData = ({ commit, dispatch, rootState }, { papeParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })


    let statusQuery = "";

      if (searchTerms.status != "")

           statusQuery = "&where=status@" + `${searchTerms.status}`;


    let dateQuery = "";

    if (searchTerms.published_start != "") {

        dateQuery = "&datetime=published_at@gte," + `${searchTerms.published_start}T00:00:00`;

    }

    if (searchTerms.published_end != "") {

        dateQuery = "&datetime=published_at@lte," + `${searchTerms.published_end}T23:59:59`;

    }

    if (searchTerms.published_end != "" && searchTerms.published_start != "") {

        dateQuery = "&datetime=published_at@between," + `${searchTerms.published_start}T00:00:00/` + `${searchTerms.published_end}T23:59:59`;

    }

    commit('SET_SEARCH_QUERY', statusQuery + dateQuery);


    return Daily.search(papeParam, statusQuery + dateQuery).then(async response => {


        var allDaily;

        if (response != '') {

            allDaily = response.data.data;

            for (const daily of allDaily) {

                if (daily.target_ids != null) {

                    daily.target_user = [];

                    for (const targetId of daily.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await daily.target_user.push(response.data.data);

                            }

                        });

                    }

                }

            }

            commit('SET_ALL_DAILY', allDaily);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })
        .catch(() => { })

        .finally(() => {

            setTimeout(() => {

                dispatch('common/setSpinnerStatus', false, { root: true })

            }, 10000);
        });

}



        
        export const inputSearchData = ({ commit, dispatch, rootState }, { pageParam, searchTerms }) => {
            dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&keyword=title@" + `${searchTerms}`);

    return Daily.inpuSearch(pageParam, searchTerms).then(async response => {

        var allDaily;

        if (response != '') {

            allDaily = response.data.data;

            for (const daily of allDaily) {

                if (daily.target_ids != null) {

                    daily.target_user = [];

                    for (const targetId of daily.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await daily.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_DAILY', allDaily);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }

    })
            .catch(() => { })
            
            .finally(() => {
                
                setTimeout(() => {

                    dispatch('common/setSpinnerStatus', false, { root: true })
    
                }, 10000);
            });
    

    }


   