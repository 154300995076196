import Api from "./Api";
import moment from "moment";
import CONST from "../const/control_panel/common";

export default {
  /**
   * All message list
   * @returns object
   */
  async list(pagination, searchQuery) {
    return Api().get(
      "api/v1/summary?type=thanks" +
      "&desc=updated_at&page=" +
      `${pagination.pageNo}` +
      "&year=" +
      `${pagination.searchYear}` +
      "&month=" +
      `${pagination.searchMonth}` +
      "&per=500"
    );
  },

  async export(pagination) {
    return Api().get(
      "api/v1/file-export?year=" +
      `${pagination.searchYear}` +
      "&month=" +
      `${pagination.searchMonth}`,
      {
        responseType: "arraybuffer",
      }
    );
  },


  async messageReport(pagination) {
    return Api().get(
      "api/v1/messages-report-export?type=" +
      `${pagination.messageType}`,
      {
        responseType: "arraybuffer",
      }
    );
  },





  /**
   * Show the currect message
   * @returns object
   */
  async show(id) {
    return Api().get("api/v1/messages/" + `${id}`);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async delete(id) {
    return Api().delete("api/v1/messages/" + `${id}`);
  },

  async filter(pagination, status) {
    return Api().get(
      "api/v1/messages?type=thanks" +
      "&desc=updated_at&page=" +
      `${pagination.pageNo}` +
      "&per=" +
      `${CONST.PAGINATION.page_count}` +
      "&where=status@" +
      `${status}`
    );
  },

  async search(pagination, searchTerms) {
    return Api().get(
      "api/v1/messages?type=thanks" +
      "&desc=updated_at&page=" +
      `${pagination.pageNo}` +
      "&per=" +
      `${CONST.PAGINATION.page_count}` +
      searchTerms
    );
  },
  async inpuSearch(pagination, searchTerms) {
    return Api().get(
      "api/v1/messages?type=thanks" +
      "&desc=updated_at&page=" +
      `${pagination.pageNo}` +
      "&per=" +
      `${CONST.PAGINATION.page_count}` +
      "&keyword=title,content@" +
      `${searchTerms}`
    );
  },
};
