import { createStore } from "vuex";

import news from "./news/index";
import file from "./file/index";
import user from "./user/index";
import common from "./common/index";
import thanks from "./thanks/index";
import idea from "./idea/index";
import daily from "./daily/index";
import challenge from "./challenge/index";
import column from "./column/index";
import sdgs from "./sdgs/index";
import event from "./event/index";
import calendar from "./calendar/index";

export default createStore({
  modules: {
    news,
    file,
    user,
    common,
    thanks,
    challenge,
    idea,
    daily,
    column,
    sdgs,
    event,
    calendar,
  },
});
