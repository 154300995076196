import News from "../../apis/News";
import router from '../../router/index'
import $ from "jquery";
import moment from "moment";


export const getAllNews = ({ commit, dispatch, rootState }, { papeParam, type }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })


    return News.list(papeParam, rootState.news.searchQuery).then(response => {

        if (response != '') {

            commit('SET_ALL_NEWS', response.data);

            rootState.common.pagination = response.data;
        }


    })
        .catch((error) => {

            // router.push({ name: 'Control Error' });
            // if (error.response) {
            //     // client received an error response (5xx, 4xx)
            // } else if (error.request) {
            //     // client never received a response, or request never left
            // } else {
            //     // anything else
            // }


        })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const createNews = ({ commit, dispatch, rootState }, { news, files }) => {


    return News.register(news).then(response => {

        var createdId = response.data.data.id;

        commit('SET_CREATED_NEWS', response.data);


        if (files.length > 0) {

            // dispatch('common/setSpinnerStatus', true, { root: true });

            return dispatch('file/uploadFile', {
                files: files,
                id: createdId,
            }, { root: true });


        } else {

            return router.push({
                name: "News Show",
                params: { id: createdId },
            });

        }

    }).catch(() => { })

        .finally(() => {

            // dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const viewNews = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    return News.show(id).then(response => {

        commit('SET_NEWS', response.data);

    })

        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const updateNews = ({ commit, dispatch, rootState }, { news, id, files }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    news.published_at = moment(

        String(news.published_at)

    ).format("YYYY-MM-DD HH:mm:ss");


    return News.update(news, id).then(response => {

        commit('SET_UPDATED_NEWS', response.data);

        if (files.length > 0) {

            return dispatch('file/uploadFile', {
                files: files,
                id: id,
            }, { root: true });

        } else {

            return router.push({
                name: "News Show",
                params: { id: id },
            });

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const deleteNews = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true });

    return News.delete(id).then(

        (response) => {

            if (response != '') {

                commit('DELETE_NEWS', id);

                $(".bulktool").removeClass("open");

                $(".command_wrap_inbox").removeClass("hide");

                $(".modal").removeClass("open");


                return dispatch('common/setSucccessMessageStatus', {

                    status: true,
                    message: "お知らせが正常に削除されました。",

                }, { root: true });

            }
        },

        (error) => {

            console.log(error);
        }
    )

        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });
}

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {

    commit('SET_DELETE_NEWS_ID', id);

}

export const setFileterStatus = ({ commit, dispatch, rootState }, { papeParam, status }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&where=status@" + `${status}`);

    return News.filter(papeParam, status).then(response => {

        if (response != '') {

            commit('SET_ALL_NEWS', response.data);

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const getSearchData = ({ commit, dispatch, rootState }, { papeParam, searchTerms }) => {


    dispatch('common/setSpinnerStatus', true, { root: true })

    let statusQuery = "&where=status@" + `${searchTerms.status}`;

    let dateQuery = "";

    if (searchTerms.published_start != "") {

        dateQuery = "&datetime=published_at@gte," + `${searchTerms.published_start}T00:00:00`;

    }

    if (searchTerms.published_end != "") {

        dateQuery = "&datetime=published_at@lte," + `${searchTerms.published_end}T23:59:59`;

    }

    if (searchTerms.published_end != "" && searchTerms.published_start != "") {

        dateQuery = "&datetime=published_at@between," + `${searchTerms.published_start}T00:00:00/` + `${searchTerms.published_end}T23:59:59`;

    }



    commit('SET_SEARCH_QUERY', statusQuery + dateQuery);

    return News.search(papeParam, statusQuery + dateQuery).then(response => {

        if (response != '') {

            commit('SET_ALL_NEWS', response.data);

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}

export const inputSearchData = ({ commit, dispatch, rootState }, { pageParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&keyword=title,content@" + `${searchTerms}`);

    return News.inpuSearch(pageParam, searchTerms).then(response => {

        if (response != '') {

            commit('SET_ALL_NEWS', response.data);

            rootState.common.pagination = response.data;

        }

    })
        .catch(() => { })

        .finally(() => {

            return dispatch('common/setSpinnerStatus', false, { root: true });

        });

}
