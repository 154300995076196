// import { param } from 'jquery'
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Front top",
    component: () => import("@/views/front_panel/Top.vue"),
    meta: {
      title: `めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "トップページです",
    },
  },
  {
    path: "/entrance",
    name: "Control entrance",
    component: () => import("@/views/Entrance.vue"),
    meta: {
      title: `エントランス|めぐるーむ-タカヤマ`,
      layout: "Entrance",
      desc: "",
    },
  },
  {
    path: "/board",
    name: "Front board archive",
    component: () => import("@/views/front_panel/board/Index.vue"),
    meta: {
      title: `掲示板|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "掲示板です",
    },
  },
  {
    path: "/board/:id",
    name: "Front board show",
    component: () => import("@/views/front_panel/board/Show.vue"),
    meta: {
      title: `掲示板詳細|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "掲示板です",
    },
  },
  {
    path: "/board/create",
    name: "Front board create",
    component: () => import("@/views/front_panel/board/Create.vue"),
    meta: {
      title: `掲示板作成|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "掲示板です",
    },
  },
  {
    path: "/event",
    name: "Front event archive",
    component: () => import("@/views/front_panel/event/Index.vue"),
    meta: {
      title: `イベント一覧|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "イベントです",
    },
  },
  {
    path: "/event/:id",
    name: "Front event show",
    component: () => import("@/views/front_panel/event/Show.vue"),
    meta: {
      title: `イベント詳細|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "イベントです",
    },
  },
  {
    path: "/:type",
    name: "Front archive",
    component: () => import("@/views/front_panel/message/Index.vue"),
    meta: {
      title: `カード一覧|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "カード一覧です",
    },
  },
  {
    path: "/:type/:id",
    name: "Front show",
    component: () => import("@/views/front_panel/message/Show.vue"),
    meta: {
      title: `カード詳細|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "カード詳細です",
    },
  },
  {
    path: "/photolog",
    name: "Front photolog",
    component: () => import("@/views/front_panel/photolog/Index.vue"),
    meta: {
      title: `フォトログ|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "フォトログです",
    },
  },
  {
    path: "/company/business",
    name: "Front business",
    component: () => import("@/views/front_panel/company/Business.vue"),
    meta: {
      title: `社是|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "社是です",
    },
  },
  {
    path: "/company/ceo",
    name: "Front ceo",
    component: () => import("@/views/front_panel/company/Ceo.vue"),
    meta: {
      title: `社長メッセージ|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "社長メッセージです",
    },
  },
  {
    path: "/company/policy",
    name: "Front policy",
    component: () => import("@/views/front_panel/company/Policy.vue"),
    meta: {
      title: `会社・部門方針|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "会社・部門方針です",
    },
  },
  {
    path: "/staff",
    name: "Front staff",
    component: () => import("@/views/front_panel/staff/Staff.vue"),
    meta: {
      title: `役員・社員紹介|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "役員・社員紹介です",
    },
  },
  {
    path: "/mypage",
    // path: '/message/',
    name: "Front mypage",
    component: () => import("@/views/front_panel/mypage/Mypage.vue"),
    meta: {
      title: `マイページ一覧|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ一覧です",
    },
  },
  {
    path: "/mypage/user",
    // path: '/message/',
    name: "Front user edit",
    component: () => import("@/views/front_panel/mypage/user/Edit.vue"),
    meta: {
      title: `ユーザープロフィール|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "ユーザープロフィールです",
    },
  },
  {
    path: "/mypage/event",
    name: "Control event",
    component: () => import("@/views/front_panel/mypage/event/Index.vue"),
    meta: {
      title: `マイページ-イベント|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-イベントです",
    },
  },
  {
    path: "/mypage/event/create",
    name: "Control eventcreate",
    component: () => import("@/views/front_panel/mypage/event/Create.vue"),
    meta: {
      title: `マイページ-イベント作成|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-イベント作成です",
    },
  },
  {
    path: "/mypage/event/:id",
    name: "Control eventshow",
    component: () => import("@/views/front_panel/mypage/event/Show.vue"),
    meta: {
      title: `マイページ-イベント詳細|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-イベント詳細です",
    },
  },
  {
    path: "/mypage/event/:id/edit",
    name: "Control eventedit",
    component: () => import("@/views/front_panel/mypage/event/Edit.vue"),
    meta: {
      title: `マイページ-イベント編集|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-イベント編集です",
    },
  },
  {
    path: "/top",
    name: "Control Login",
    component: () => import("@/views/front_panel/mypage/message/Show.vue"),
    meta: {
      title: `ログイン|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "ログインです",
    },
  },
  {
    path: "/mypage/:type",
    // path: '/message/',
    name: "Control message",
    component: () => import("@/views/front_panel/mypage/message/Index.vue"),
    meta: {
      title: `マイページ-カード一覧|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-カード一覧です",
    },
  },
  {
    path: "/mypage/:type/create",
    name: "Control messagecreate",
    component: () => import("@/views/front_panel/mypage/message/Create.vue"),
    meta: {
      title: `マイページ-カード作成|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-カード作成です",
    },
  },
  {
    path: "/mypage/:type/:id",
    // path: '/message/',
    name: "Control messageshow",
    component: () => import("@/views/front_panel/mypage/message/Show.vue"),
    meta: {
      title: `マイページ-カード作成|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-カード作成です",
    },
  },
  {
    path: "/mypage/:type/:id/edit",
    // path: '/message/',
    name: "Control messageedit",
    component: () => import("@/views/front_panel/mypage/message/Edit.vue"),
    meta: {
      title: `マイページ-カード編集|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "マイページ-カード編集です",
    },
  },
  {
    path: "/top",
    name: "Control Login",
    component: () => import("@/views/front_panel/mypage/message/Show.vue"),
    meta: {
      title: `ログイン|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "ログインです",
    },
  },
  {
    path: "/approval",
    name: "Front approval",
    component: () => import("@/views/front_panel/approval/Index.vue"),
    meta: {
      title: `承認・申請一覧|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "承認・申請一覧です",
    },
  },
  // {
  //     path: '/control-panel-home',
  //     name: 'Control Home',
  //     component: () =>
  //         import('@/views/control_panel/Home.vue'),
  //     meta: {
  //         title: `About|めぐるーむ-タカヤマ`,
  //         layout: 'ControlPanel'
  //     }
  // },
  {
    path: "/admin",
    name: "Dashboard",
    component: () => import("@/views/control_panel/Dashboard.vue"),
    meta: {
      title: `管理画面-ダッシュボード|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/home",
    name: "Control User",
    component: () => import("@/views/front_panel/Home.vue"),
    meta: {
      title: `User|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/company/policy/edit",
    name: "Page Policy Edit",
    component: () => import("@/views/control_panel/policy/Edit.vue"),
    meta: {
      title: `管理画面-企業情報編集|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/company/policy",
    name: "Page Policy Show",
    component: () => import("@/views/control_panel/policy/Show.vue"),
    meta: {
      title: `管理画面-企業情報詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/company/:type/edit",
    name: "Page Edit",
    component: () => import("@/views/control_panel/page/Edit.vue"),
    meta: {
      title: `管理画面-企業情報編集|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/company/:type",
    name: "Page Show",
    component: () => import("@/views/control_panel/page/Show.vue"),
    meta: {
      title: `管理画面-企業情報詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/board",
    name: "Admin board archive",
    component: () => import("@/views/control_panel/board/Index.vue"),
    meta: {
      title: `管理画面-掲示板一覧|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/board/:id",
    name: "Admin board show",
    component: () => import("@/views/control_panel/board/Show.vue"),
    meta: {
      title: `管理画面-掲示板詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/:type",
    name: "Admin message",
    component: () => import("@/views/control_panel/message/Index.vue"),
    meta: {
      title: `管理画面-カード一覧|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/:type/create",
    name: "Admin messagecreate",
    component: () => import("@/views/control_panel/message/Create.vue"),
    meta: {
      title: `管理画面-カード作成|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/:type/:id",
    name: "Admin messageshow",
    component: () => import("@/views/control_panel/message/Show.vue"),
    meta: {
      title: `管理画面-カード詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/:type/:id/edit",
    name: "Admin messageedit",
    component: () => import("@/views/control_panel/message/Edit.vue"),
    meta: {
      title: `管理画面-カード編集|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },

  // {
  //   path: "/admin/main-panel-home",
  //   name: "Main Home",
  //   component: () => import("@/views/main_panel/Home.vue"),
  //   meta: {
  //     title: `About|めぐるーむ-タカヤマ`,
  //     layout: "MainPanel",
  //   },
  // },
  {
    path: "/front/error_c",
    name: "Front Error Catch",
    component: () => import("@/views/front_panel/Error_c.vue"),
    meta: {
      title: `エラー|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "",
      //isPublic: true,
    },
  },
  {
    path: "/front/error",
    name: "Front Error",
    component: () => import("@/views/front_panel/Error.vue"),
    meta: {
      title: `エラー|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "",
      //isPublic: true,
    },
  },
  {
    path: "/control/error_c",
    name: "Control Error Catch",
    component: () => import("@/views/control_panel/Error_c.vue"),
    meta: {
      title: `エラー|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/control/error",
    name: "Control Error",
    component: () => import("@/views/control_panel/Error.vue"),
    meta: {
      title: `エラー|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/admin/event",
    name: "Event",
    component: () => import("@/views/control_panel/event/List.vue"),
    meta: {
      title: `管理画面-イベント一覧|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },

  {
    path: "/admin/event/create",
    name: "Event Create",
    component: () => import("@/views/control_panel/event/Create.vue"),
    meta: {
      title: `管理画面-イベント作成|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/event/:id/edit",
    name: "Event Edit",
    component: () => import("@/views/control_panel/event/Edit.vue"),
    meta: {
      title: `管理画面-イベント編集|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/event/:id/show",
    name: "Event Show",
    component: () => import("@/views/control_panel/event/Show.vue"),
    meta: {
      title: `管理画面-イベント詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },

  // {
  //   path: "/admin/thanks",
  //   name: "Thanks",
  //   component: () => import("@/views/control_panel/thanks/List.vue"),
  //   meta: {
  //     title: `Thanks|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },

  // {
  //   path: "/admin/thanks/:id/show",
  //   name: "Thanks Show",
  //   component: () => import("@/views/control_panel/thanks/Show.vue"),
  //   meta: {
  //     title: `Thanks Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/idea",
  //   name: "Idea",
  //   component: () => import("@/views/control_panel/idea/List.vue"),
  //   meta: {
  //     title: `Idea|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/idea/:id/show",
  //   name: "Idea Show",
  //   component: () => import("@/views/control_panel/idea/Show.vue"),
  //   meta: {
  //     title: `Idea Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/daily",
  //   name: "Daily",
  //   component: () => import("@/views/control_panel/daily/List.vue"),
  //   meta: {
  //     title: `Daily|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/daily/:id/show",
  //   name: "Daily Show",
  //   component: () => import("@/views/control_panel/daily/Show.vue"),
  //   meta: {
  //     title: `Daily Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/column",
  //   name: "Column",
  //   component: () => import("@/views/control_panel/column/List.vue"),
  //   meta: {
  //     title: `Column|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/column/:id/show",
  //   name: "Column Show",
  //   component: () => import("@/views/control_panel/column/Show.vue"),
  //   meta: {
  //     title: `Column Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/sdgs",
  //   name: "Sdgs",
  //   component: () => import("@/views/control_panel/sdgs/List.vue"),
  //   meta: {
  //     title: `Sdgs|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/sdgs/:id/show",
  //   name: "Sdgs Show",
  //   component: () => import("@/views/control_panel/sdgs/Show.vue"),
  //   meta: {
  //     title: `Sdgs Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  // {
  //   path: "/admin/challenge",
  //   name: "Challenge",
  //   component: () => import("@/views/control_panel/challenge/List.vue"),
  //   meta: {
  //     title: `Challenge|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },

  // {
  //   path: "/admin/challenge/:id/show",
  //   name: "Challenge Show",
  //   component: () => import("@/views/control_panel/challenge/Show.vue"),
  //   meta: {
  //     title: `Challenge Show|めぐるーむ-タカヤマ`,
  //     layout: "ControlPanel",
  //   },
  // },
  {
    path: "/admin/user",
    name: "User",
    component: () => import("@/views/control_panel/user/List.vue"),
    meta: {
      title: `管理画面-ユーザー一覧|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/user/create",
    name: "User Create",
    component: () => import("@/views/control_panel/user/Create.vue"),
    meta: {
      title: `管理画面-ユーザー作成|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/user/:id/show",
    name: "User Show",
    component: () => import("@/views/control_panel/user/Show.vue"),
    meta: {
      title: `管理画面-ユーザー詳細|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/user/:id/edit",
    name: "User Edit",
    component: () => import("@/views/control_panel/user/Edit.vue"),
    meta: {
      title: `管理画面-ユーザー編集|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },
  {
    path: "/admin/calendar",
    name: "Calendar",
    component: () => import("@/views/control_panel/calendar/Index.vue"),
    meta: {
      title: `管理画面-カレンダー|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
      desc: "",
    },
  },

  {
    path: "/:catchAll(.*)",
    component: () => import("@/errors/control_panel/404.vue"),
  },

  {
    path: "/login",
    name: "Control Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: `ログイン|めぐるーむ-タカヤマ`,
      layout: "Login",
      desc: "",
      isPublic: true,
    },
  },
  {
    path: "/*",
    name: "Front 404",
    component: () => import("@/views/front_panel/404.vue"),
    meta: {
      title: `404|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "",
    },
  },
  {
    path: "/404",
    name: "Front 404",
    component: () => import("@/views/front_panel/404.vue"),
    meta: {
      title: `404|めぐるーむ-タカヤマ`,
      layout: "FrontPanel",
      desc: "",
    },
  },

  {
    path: "/admin/summary",
    name: "Thanks",
    component: () => import("@/views/control_panel/thanks/ThanksSummary.vue"),
    meta: {
      title: `集計ページ|めぐるーむ-タカヤマ`,
      layout: "ControlPanel",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  router["referrer"] = from;
  next();
});

export default router;
