import moment from "moment";

export const SET_ALL_EVENT = (state, event) => {

    state.pagination = event
    state.allEvent = event.data;

}

export const SET_CREATED_EVENT = (state, event) => {

    state.createdEvent = event.data;

}

export const SET_EVENT = (state, event) => {

    event.data.published_at = moment(

        String(event.data.published_at)

    ).format("YYYY-MM-DD HH:mm:ss");

    state.showEvent = event.data;
}

export const SET_UPDATED_EVENT = (state, event) => {

    state.updatedEvent = event.data;

}

export const DELETE_EVENT = (state, id) => {

    state.allEvent = state.allEvent.filter(item => {

        return item.id != id;

    })
}

export const SET_DELETE_EVENT_ID = (state, id) => {

    state.deleteId = id;

}
export const SET_PAGINATION = (state, event) => {

    state.pagination = event;
}
export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}

export const SET_ALL_ENTRY = (state,entry)=>{

    state.allEntry = entry.data;

}

export const SET_UPDATE_ALL_ENTRY = (state,entry)=>{
    
    state.updateAllEntry = entry.data;

}