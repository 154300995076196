import Api from "./Api";

export default {
  async list() {
    return Api().get("api/v1/schedules");
  },

  async schedulesRule() {
    return Api().get("api/v1/calender/schedules");
  },
  async createSchedules(data) {
    return Api().post("api/v1/schedules", data);
  },
  async deleteSchedules(id) {
    return Api().delete("api/v1/schedules/" + id);
  },

  async scheduleEventRemove(id, data) {
    return Api().post("api/v1/calender/event_remove/" + id, data);
  },
  async updateSchedules(id, data) {
    return Api().put("api/v1/schedules/" + id, data);
  },
};
