export const SET_LOGIN_USER = (state, user) => {
  localStorage.setItem("user", JSON.stringify(user.data));

  state.loginUser = JSON.parse(localStorage.getItem("user")) || [];
};

export const SET_USER = (state, user) => {
  state.user = user.data;
};

export const SET_VIEW_USER = (state, user) => {
  state.viewUser = user.data;
};

export const SET_ALL_USERS = (state, allUsers) => {
  state.allUsers = allUsers.data;
};

export const SET_PAGINATION = (state, allUsers) => {
  state.pagination = allUsers.data;
};

export const SET_SEARCH_QUERY = (state, query) => {
  state.searchQuery = query;
};

export const SET_VALIDATION_ERROR = (state, query) => {
  state.validationError = query;
};

export const DELETE_USER = (state, id) => {
  state.allUsers = state.allUsers.filter((item) => {
    return item.id != id;
  });
};

export const SET_DELETE_USER_ID = (state, id) => {
  state.userDeleteId = id;
};

export const SET_GROUPS = (state, groups) => {

  state.groups = groups.data.filter((item) => {
    return item.name != "開発グループ";
  });

};

export const SET_ROLE = (state, role) => {
  
  state.role = role.data.filter((item) => {
    return item.parent_id == null;
  });
};
