import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {

    /**
     * message Insert 
     * @param {*} form 
     * @returns object
     */
    async register(form) {

        return Api().post("api/v1/messages", form);

    },


    /**
     * All message list
     * @returns object
     */
    async list(pagination, searchQuery) {

        return Api().get("api/v1/messages?type=news"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + searchQuery);

    },

    /**
     * Show the currect message
     * @returns object
     */
    async show(id) {

        return Api().get("api/v1/messages/" + `${id}`);

    },

    /**
     * Edit the currect message
     * @returns object
     */
    async edit(id) {

        return Api().get("api/v1/messages/" + `${id}`);

    },

    /**
     * message Update 
     * @param {*} form ,id
     * @returns object
     */
    async update(form, id) {

        return Api().put("api/v1/messages/" + `${id}`, form);

    },


    /**
     * delete the currect message
     * @returns boolean
     */
    async delete(id) {

        return Api().delete("api/v1/messages/" + `${id}`);

    },

    async filter(pagination, status) {

        return Api().get("api/v1/messages?type=news"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + "&where=status@"
            + `${status}`);

    },

    async search(pagination, searchTerms) {

        return Api().get("api/v1/messages?type=news"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + searchTerms
        );

    },
    async inpuSearch(pagination, searchTerms) {

        return Api().get("api/v1/messages?type=news"
            + "&desc=updated_at&page="
            + `${pagination.pageNo}`
            + "&per="
            + `${CONST.PAGINATION.page_count}`
            + "&keyword=title,content@"
            + `${searchTerms}`
        );

    }


};