import File from "../../apis/File";
import route from "../../router/index";

export const uploadFile = ({ commit, dispatch }, { files, id }) => {
  for (var i = 0; i < files.length; i++) {
    File.fileUpload(files[i], id)
      .then(
        (response) => {
          if (response) {
            commit("SET_LATEST_CREATED_FILE", response.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {})

      .finally(() => {
        dispatch("common/setSpinnerStatus", false, { root: true });

        // router navigation
        return route.push({
          name: "News Show",
          params: { id: id },
        });
      });
  }
};

export const uploadEventFile = ({ commit, dispatch }, { files, id }) => {
  for (var i = 0; i < files.length; i++) {
    File.fileUploadEvent(files[i], id)
      .then(
        (response) => {
          if (response) {
            commit("SET_LATEST_CREATED_FILE", response.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {})

      .finally(() => {
        dispatch("common/setSpinnerStatus", false, { root: true });

        // router navigation
        return route.push({
          name: "Event Show",
          params: { id: id },
        });
      });
  }
};

export const getFileEvent = ({ commit, dispatch }, id) => {
  return File.getFileEvent(id)
    .then((response) => {
      commit("SET_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getFile = ({ commit, dispatch }, id) => {
  return File.getFile(id)
    .then((response) => {
      commit("SET_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const delteFile = ({ commit, dispatch }, { id, fileId }) => {
  return File.deleteFile(id, fileId)
    .then((response) => {
      commit("SET_LATEST_DELETED_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const delteFileEvent = ({ commit, dispatch }, { id, fileId }) => {
  return File.delteFileEvent(id, fileId)
    .then((response) => {
      commit("SET_LATEST_DELETED_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateFile = ({ commit, dispatch }, { file, newsId, fileId }) => {
  return File.updateFile(file, newsId, fileId)
    .then((response) => {
      commit("SET_LATEST_UPDATED_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateFileEvent = (
  { commit, dispatch },
  { file, eventId, fileId }
) => {
  return File.updateFileEvent(file, eventId, fileId)
    .then((response) => {
      commit("SET_LATEST_UPDATED_FILE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getFileStoreage = ({ commit, dispatch }, { file, file_name }) => {
  return File.fileStorageName(file)
    .then((response) => {
      commit("SET_FILE_STOREAGE", response.data);

      /**
       * 新規タブ
       */
      // window.open(window.URL.createObjectURL(new Blob([response.data])))

      /**
       * 疑似ダウンロード
       */

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", file_name);
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const uploadProfilePicture = ({ commit, dispatch }, { file, id }) => {
  File.imageUpload("users", file, id)
    .then(
      (response) => {
        if (response) {
          commit("SET_LATEST_CREATED_FILE", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getPicture = ({ commit, dispatch }, id) => {
  return File.getPicture("users", id)
    .then((response) => {
      commit("SET_PICTURE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateProfilePicture = (
  { commit, dispatch },
  { modelId, id, file, isDelete }
) => {
  // if (isDelete) {
  //   File.imageDelete("users", modelId, id).then(
  //     (response) => {},
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  File.imageUpload("users", file, modelId)
    .then(
      (response) => {
        if (response) {
          commit("SET_LATEST_CREATED_FILE", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const deleteProfilePicture = ({ commit, dispatch }, { modelId, id }) => {
  File.imageDelete("users", modelId, id);
};

export const uploadEventEyeCatch = ({ commit, dispatch }, { file, id }) => {
  File.imageUpload("events", file, id)
    .then(
      (response) => {
        if (response) {
          commit("SET_LATEST_CREATED_FILE", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getEventEyeCatch = ({ commit, dispatch }, id) => {
  return File.getPicture("events", id)
    .then((response) => {
      commit("SET_PICTURE", response.data);
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateEventEyeCatch = (
  { commit, dispatch },
  { modelId, id, file, isDelete }
) => {
  // if (isDelete) {
  //   File.imageDelete("events", modelId, id).then(
  //     (response) => {},
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  File.imageUpload("events", file, modelId)
    .then(
      (response) => {
        if (response) {
          commit("SET_LATEST_CREATED_FILE", response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    )
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const deleteEventEyeCatch = ({ commit, dispatch }, { modelId, id }) => {
  File.imageDelete("events", modelId, id);
};
