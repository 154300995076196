export const getEventItems = (state) => {
    return state.allEvent;
}

export const displayEvent = (state) => {
    return state.showEvent;
}

export const getType = (state) => {

    return state.showEvent.type ? 'イベント' : '';

}