<template>
<div class="bg_img" :style="{ backgroundImage: 'url(' + require('@/assets/login/images/bg.jpg') + ')' }"></div>
<div id="all_wrap">	
  <main>
    <section>
    <div class="content_wrap">
      <div class="logo_box">
      <div class="logo_wrap">
          <img src="@/assets/login/images/logo_w.png" alt="ロゴ">
      </div>
        <div class="left_area">
            <div class="outbox">
              <div class="inner">
                <div class="title_wrap">
                  <h2 class="ttl_login">ログイン</h2>
                </div>
                <div v-if="errors!=null" class="err_wrap">
                      <span>{{errors}}</span>
                  </div>
                  <Form
                    enctype="multipart/form-data"
                    autocomplete="off"
                    @submit="login"
                    v-slot="{ errors }">

                  <div class="form_outwrap">
                      <div class="wrap wid_100per p_r0">
                        <div class="in_wrap">
                          <div class="input_wrap">
                            <label for="email"><i class="fas fa-envelope"></i>Email</label>
                            <Field
                                  name="email"
                                  type="text"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors.email }"
                                  v-model="user.email"
                                  :rules="isRequired"
                                />
                                <ErrorMessage
                                  class="each_msg"
                                  name="email"
                                />
                          </div>
                        </div>
                      </div>  
                      <div class="wrap wid_100per p_r0 m_b20">
                        <div class="in_wrap">
                          <div class="input_wrap">
                            <label for="password"><i class="fas fa-lock"></i>Password</label>
                            <Field
                                  name="password"
                                  type="password"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors.password }"
                                  v-model="user.password"
                                  :rules="isRequired"
                                />
                                <ErrorMessage
                                  class="each_msg"
                                  name="password"
                                />
                          </div>
                        </div>
                      </div>                                                                                                      
                  </div>
                  <div class="t_c m_t20 m_b20">
                  <div class="btn_log"><button type="submit">ログイン<i class="fas fa-sign-in-alt m_l10"></i></button></div></div>	           
                  </FORM>     
              </div>
          </div>
        </div>

        <div class="right_area" :style="{ backgroundImage: 'url(' + require('@/assets/login/images/bg.jpg') + ')' }">
          <div class="logo_wrap">
            <img src="@/assets/login/images/logo_w.png" alt="ロゴ">
          </div>
        </div>
      </div>

           

    </div>     
</section>
  </main>
  <footer>
			<p class="copyright">&copy;タカヤマ&nbsp;2021&nbsp;Allrights&nbsp;Reserved.</p>
		</footer>	
</div>
  <!--/component_main-->
</template>

<script>
// @ is an alias to /src
import User from "@/apis/User";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  data() {
    return {
      phone: "",
      errors: null,
      user:{
        email:null,
        password:null,
      },
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  created() {
    this.phoneMatch();
    this.top();
  },
  mounted() {
    document.body.className = 'login_page';
  },
  methods: {
    isRequired(value,name) {
      var field_name=null;
      if (value) {
        return true;
      }
      if(name.field == 'email'){
        field_name = 'メールアドレス';
      }else if(name.field == 'password'){
        field_name = 'パスワード';
      }
    return field_name + 'は必須です';
    },
    phoneMatch: function () {
      var ua = navigator.userAgent
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
        this.phone = true;
        document.body.id = 'sp_style';
      }
      else {
        this.phone = false;
        document.body.id = 'pc_style';
      }
    },
    login() {
       User.login(this.user)
       .then(
          (response) => {
            if (response != null) {
              console.log(response);
              if(response.data.data.is_admin == 1){
                console.log("admin");
                if(this.$route.query.redirect){
                  this.$router.push({ path: this.$route.query.redirect });
                }else{
                  var ua = navigator.userAgent
                  if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
                    this.$router.push({ name: "Front top" });
                  }else {
                    this.$router.push({ name: "Control entrance" });
                  }
                  
                }
              }else{
                if(this.$route.query.redirect){
                  this.$router.push({ path: this.$route.query.redirect });
                }else{
                  this.$router.push({ name: "Front top" });
                }
                
              }
              
            }
          },
          (error) => {
            console.log('error');
            console.log(error);
            if(error == 'Error: Request failed with status code 422'){
              this.errors = 'ログインに失敗しました';
            }else{
              this.$router.push({ name: 'Front Error' });
            }
          }
        )
        .catch(() => {
          this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
      
    },
    top() {
      // showUser
      this.loader = true;
      User.top()
      .then(
          (response) => {
            if (response != null) {
              console.log(response);
            }
          },
          (error) => {
            console.log('error');
            console.log(error);
          }
        )
        .catch((error) => {
          console.log('catch');
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
label {
  margin-right: 100px;
}
.form-group,
.btn-primary {
  margin-top: 20px;
}
</style>
<style lang="scss">
/* 外部CSSスタイルシートを追加する */
@import "../assets/login/sass/style-login-pc.scss";
</style>