import moment from "moment";

export const SET_ALL_THANKS = (state, thanks) => {
  state.allThanks = thanks;
};

export const SET_PAGINATION = (state, thanks) => {
  state.pagination = thanks;
};

export const SET_THANKS = (state, thanks) => {
  state.showThanks = thanks;
};

export const DELETE_THANKS = (state, id) => {
  state.allThanks = state.allThanks.filter((item) => {
    return item.id != id;
  });
};

export const SET_DELETE_TANKS_ID = (state, id) => {
  state.thanksDeleteId = id;
};

export const SET_SEARCH_QUERY = (state, query) => {
  state.searchQuery = query;
};
