

export const SET_ALL_COLUMN = (state, column) => {

    state.allColumn = column;

}
export const SET_PAGINATION = (state, column) => {

    state.pagination = column;
}


export const SET_COLUMN = (state, column) => {

    state.showColumn = column;

}

export const SET_DELETE_COLUMN_ID = (state, id) => {

    state.columnDeleteId = id;

}

export const DELETE_NEWS = (state, id) => {

    state.allColumn = state.allColumn.filter(item => {

        return item.id != id;

    })
}
export const DELETE_COLUMN = (state, id) => {
    state.allColumn = state.allColumn.filter((item) => {
      return item.id != id;
    });
  };
export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}