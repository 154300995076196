<template>
<Logout> </Logout>
  <header id="g_header">
    <div class="side_open_except" style="display: none"></div>

    <div class="circle_wrap tooltip tip-right" title="メニューの開閉">
      <a href="#" class="button-left-sidebar trigger-toggle-sidebar circle-btn"
        ><i class="fas fa-bars"></i
      ></a>
    </div>
    <div class="left_menu">
      <div class="logo_wrap bo_logo">
        <a href="">
          <img src="@/assets/control_panel/images/logo_w.png" alt="" />
        </a>
      </div>
      <div class="g_menu_trigger tooltip tip-left" title="メニューの開閉">
        <span><i class="material-icons">menu</i></span>
      </div>
      <div class="account_choose sp_none" id="account_nav" aria-label="説明">
        <span>株式会社 タカヤマ</span>
      </div>

      <!-- <div class="left_iconmenu icon_wrap">
        <a class="tooltip bottom" aria-label="説明"><i class="material-icons">cached</i></a>
        <router-link
          :to="{ name: 'Front top' }"
          target="_blank"
          dusk="to_web_page"
          class="tooltip tip-bottom"
          ><i class="fas fa-home"></i
        ></router-link>
        <a class="tooltip tip-bottom" title="統計を確認" href="http://moka-takayama.connect-factory.com/moka/takayama/ma" dusk="to_ma"><i class="far fa-chart-bar fontawesome"></i></a>
        <a class="tooltip tip-bottom unprepared" title="準備中"><i class="far fa-question-circle fontawesome"></i></a>
        <a class="tooltip bottom" aria-label="説明"><i class="material-icons">cloud_upload</i></a>
      </div> -->

      <!-- <div class="theme">
        <span class="using_theme">使用中のサイトテーマ</span>
        <span>標準</span>
      </div> -->
    </div>
    <div class="right_menu">
      <div class="user_wrap" id="user_nav" title="ユーザーメニュー">
        <div class="info_wrap" dusk="system_kanri_popup">
          <span class="user_name"
            >{{ $User.last_name+ " " + $User.first_name }} 
          </span>
        </div>
        <span class="user_icon">
          <img id="user_img" class="user_img"
            src="@/assets/control_panel/images/user-default.png"
            alt="デフォルトユーザー画像"
          />
        </span>
        <div class="user_ctl">
          <div class="wrap clearfix">
            <div class="left">
              <span class="user_name">株式会社 タカヤマ </span>
              <span class="user_name"
                >{{ $User.last_name }} {{ $User.first_name }}</span
              >
            </div>
            <div class="right">
              <span class="user_icon">
                <img class="user_img" id="user_img_popup"
                  src="@/assets/control_panel//images/user-default.png"
                  alt="デフォルトユーザ画像"
                />
              </span>
            </div>
          </div>
          <nav>
            <ul>
              <!-- <li>
                <a href="" dusk="to_my_profile">ユーザープロフィール</a>
              </li> -->
              <li>
                <router-link :to="{ name: 'Front top' }"
                  >一般ページ</router-link
                >
              </li>
              <li class="sp_none">
                <a
                  @click="logout()"
                  href="javascript:void(0)"
                  class="logout"
                  dusk="logout_btn"
                  >ログアウト</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <!--user_ctl-->
      </div>
      <div class="logoutbtn pc_none">
        <a @click="logout()" class="logout">ログアウト</a>
      </div>
    </div>
  </header>
  <!--#g_header-->
  <div class="account_choose pc_none" id="account_nav" aria-label="説明">
    <span>株式会社 タカヤマ</span>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import User from "@/apis/User";
import Api from "@/apis/Api";
import $ from "jquery";
import Logout from "../../components/control_panel/Logout.vue"

export default {
  name: "Header",
  components: {
    Logout
  },
  computed: {},
  watch() {},
  mounted() {},
  created() {
    this.getUserImg();
  },
  methods: {
    logout() {
      let router = this.$router;
     

      $("#logOutConfirm").addClass("open");
      $("#logout-btn").click(function () {
      $("#logOutConfirm").removeClass("open");
      User.logout()
        .then((response) => {
          console.log(response);
          router.push({ name: "Control Login" });
        })
        .catch(() => {})
        .finally(() => {
          this.loader = false;
        });
      });
    },
    getUserImg(){
      Api().get("api/v1/files/users/" + this.$User.id +"?where=key@users")
          .then(
          (response) => {
            if (response.data.data.length > 0) {
              let img = document.getElementById('user_img');
              let imgPopup = document.getElementById('user_img_popup');
              img.src = 'data:image/jpeg;base64,' + response.data.data[0].base64;
              imgPopup.src = 'data:image/jpeg;base64,' + response.data.data[0].base64;
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
          });
    }
  },
};
</script>