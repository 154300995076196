<template>
  <!-- components -->
  <ControlPanel v-if="$route.meta.layout == 'ControlPanel'" />
  <MainPanel v-if="$route.meta.layout == 'MainPanel'" />
  <Login v-if="$route.meta.layout == 'Login'" />
  <Entrance v-if="$route.meta.layout == 'Entrance'" />
  <FrontPanel v-if="$route.meta.layout == 'FrontPanel'" />
</template>

<script>
// @ is an alias to /src
import ControlPanel from "@/views/control_panel/ControlPanel.vue";
import FrontPanel from "@/views/front_panel/FrontPanel.vue";
import MainPanel from "@/views/MainPanel.vue";
import Login from "@/views/Login.vue";
import Entrance from "@/views/Entrance.vue";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.min.css";
import $ from "jquery";
import jQuery from "jquery";

export default {
  name: "App",
  components: {
    ControlPanel,
    MainPanel,
    FrontPanel,
    Login,
    Entrance,
  },
  methods: {
    createTitleDesc: function (routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title = "めぐるーむ-タカヤマ";
      }

      // メタタグdescription設定
      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc;
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "めぐるーむ-タカヤマ");
      }
    },
  },
  watch: {
    $route(routeInstance, from) {
      this.createTitleDesc(routeInstance);
    },
  },
  mounted() {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
    /*---------------------------
    jQuery
    ---------------------------*/
    /* ----------------------------------------
    Sidebar
    ---------------------------------------- */

    $(window).on("load", function () {
      $("#content >*").delay(50).css({
        opacity: "1",
        left: "0",
        transition: "all ease 0.5s",
      });
    });

    $(document).ready(function () {
      var hsize = $(window).height();
      $(".maincontent").css("min-height", hsize - 60 + "px");
      $(".tooltip").tooltip({
        show: false,
        hide: false,
        tooltipClass: "tipinfo",
      });
      $(".tip-top").tooltip({
        position: {
          my: "center top",
          at: "center top",
        },
      });
      $(".tip-bottom").tooltip({
        position: {
          my: "center bottom",
          at: "center top",
        },
      });
      $(".tip-left").tooltip({
        position: {
          my: "left top",
          at: "right top",
        },
      });
      $(".tip-right").tooltip({
        position: {
          my: "right top",
          at: "left top",
        },
      });
    });
    $(function () {
      $(document).on("click", ".acc_menu", function () {
        var $this = $(this);
        var html = "";
        // メニュー表示/非表示
        $this.stop();
        $this.next(".acc_wrap").stop();
        $this.next(".acc_wrap").slideToggle("fast");
        $this.toggleClass("menuopen");
        $(".btn-area").append(html);
      });
    });

    $(function () {
      //$('#sidenav li a').each(function(){
      //    var $href = $(this).attr('href');
      //   if(location.href.match($href)) {
      //        $(this).addClass('current');
      //        $(this).parents('.prt').addClass('active');
      //    }
      //});

      $(".menu-trigger").on("click", function () {
        $(this).parent().toggleClass("open");
        $(this).toggleClass("active").next().slideToggle();
      });

      /*=======================================
  タブ切り替え
  =========================================*/

      // タブメニューをクリックしたとき
      $(".js-tab > li").on("click", function () {
        $(".js-tab > li,.js-tab_content").removeClass("active");
        var tabClass = $(this).attr("class");
        $(this).addClass("active");
        $(".js-tab_content").each(function () {
          if ($(this).attr("class").indexOf(tabClass) != -1) {
            $(this).addClass("active").fadeIn();
          } else {
            $(this).hide();
          }
        });
      });

      $(".filter_wrap .delete").on("click", function () {
        $(this).closest(".filter").hide();
      });

      $(document).on("click", ".msg_remove", function (e) {
        var $el = $(e.currentTarget);
        var $row = $el.closest(".action_wrap");
        $row.fadeOut(300, function () {
          $row.remove();
        });
      });

      $(document).on("click", ".item_doc_add_btn", function (e) {
        var count = 2;
        var $target = $("#itemlist_news");
        var $el = $(e.currentTarget);
        var $itembox = $("#itembox_doc");
        var htmlString = $("#item_doc_Template").html();
        console.log($itembox);

        $itembox.append(htmlString);

        jQuery(".tooltip").tooltip({
          show: false,
          hide: false,
          tooltipClass: "tipinfo",
        });
        $(".tip-top").tooltip({
          position: {
            my: "center top",
            at: "center top",
          },
        });
        $(".tip-bottom").tooltip({
          position: {
            my: "center bottom",
            at: "center top",
          },
        });
        $(".tip-left").tooltip({
          position: {
            my: "left top",
            at: "right top",
          },
        });
        $(".tip-right").tooltip({
          position: {
            my: "right top",
            at: "left top",
          },
        });
        $("li.new_item,div.new_item").each(function (i) {
          $(this)
            .find("input[type=file]")
            .attr("id", "file" + (i + 1));
          $(this)
            .find(".fileup-btn")
            .attr("for", "file" + (i + 1));
          //$(this).find(".filename").attr('class','js-file' + (i+1));
        });
      });

      $(".boxmin").on("click", function () {
        $(this).toggleClass("open");
      });

      $(".add_select").click(function () {
        $(".addin").append(
          '<div class=""><div class="wrap wid_30per"><div class="in_wrap"><div class="select_wrap"><select required><option value="" disabled selected>オプションを選択</option><option value="">オプション1</option><option value="">オプション2</option><option value="">オプション3</option><option value="">オプション4</option></select></div></div></div></div> '
        );
      });

      $(document).on("click", "button[type=submit]", function () {
        var flg = true;
        $("#form1")
          .find(".input-file")
          .each(function () {
            var jdg = $(this);
            console.log(jdg);
            if (jdg.val() == "") {
              flg = false;
            }
          });
        if (flg == false) {
          //alert('未選択のファイルがあります');
          return false;
        } else {
          console.log("else");
        }
      });

      $(document).on("click", ".delalert", function () {
        var result = confirm("本当に削除しますか？");
        if (result) {
          //いいえを選んだときの処理
          //はいを選んだときの処理
        } else {
          //いいえを選んだときの処理
          return false;
        }
      });
    });

    $(document).on("click", function (e) {
      if (
        !$(e.target).closest(".user_ctl").length &&
        !$(e.target).closest("#user_nav").length
      ) {
        $(".user_ctl").removeClass("active");
      } else if ($(e.target).closest("#user_nav").length) {
        $(".user_ctl").addClass("active");
      }
    });
    $(document).on("click", function (e) {
      if (
        !$(e.target).closest(".account_ctl").length &&
        !$(e.target).closest("#account_nav").length
      ) {
        $(".account_ctl").removeClass("active");
      } else if ($(e.target).closest("#account_nav").length) {
        $(".account_ctl").addClass("active");
      }
    });
    $(document).on("click", function (e) {
      $(".status_ctl").removeClass("active");
      if (
        !$(e.target).closest(".status_ctl").length &&
        !$(e.target).closest(".status_wrap").length
      ) {
        //alert('外側がクリックされました。');
        $(e.target).find(".status_ctl").removeClass("active");
        //alert(1);
      } else if ($(e.target).closest(".status_wrap").length) {
        // ３．ポップアップの表示状態の判定
        $(e.target).find(".status_ctl").addClass("active");
      }
    });

    $(document).on("click", function (e) {
      $(".select_wrap").removeClass("open");
      if (!$(e.target).closest(".select_wrap select").length) {
        $(e.target).parent().removeClass("open");
      } else {
        $(e.target).parent().addClass("open");
      }
    });

    $(document).on("click", ".modalbtn[data-target]", function () {
      $("#" + this.dataset.target).toggleClass("open");
    });
    $(document).on("click", ".popup", function (e) {
      if (e.target === this) {
        $(this).toggleClass("open");
      }
    });
    $(".close,.close_btn").on("click", function () {
      $(".popup").removeClass("open");
      return false;
    });

    $(function () {
      $(document).on("click", function (e) {
        if (
          !$(e.target).closest(".cln_select.active").length &&
          !$(e.target).closest(".datepickbox").length &&
          !$(e.target).closest(".hasDatepicker").length &&
          !$(e.target).is(".ui-datepicker-prev,.ui-datepicker-next")
        ) {
          // console.log($("title").length);
          $(".cln_select").removeClass("active");
          $(".datepickbox").removeClass("active");
        } else if ($(e.target).closest(".datepickbox").length) {
          console.log($(this).closest(".datepickbox").length);
          $(".cln_select").addClass("active");
          $(".datepickbox").addClass("active");
        }
      });
      $(".close,.close_btn").on("click", function () {
        $(this).parent(".cln_select").removeClass("active");
        $(this).parents(".datepickbox").removeClass("active");
        return false;
      });
    });

    /*** イベントアイキャッチアップロード ***/
    /*** 各ページ画像 ***/
    $(document).on("change", ".ec_catch", function (e) {
      const sizeLimit = 2048 * 2048 * 1;

      $("#prev img").remove();
      $("#prev").removeClass("temp");
      var $this = $(this);
      var file = $this.prop("files")[0];
      //   var file = event.target.files[0];
      var permit_type = ["image/jpeg"]; //アップロードできるファイルタイプ
      if (file && permit_type.indexOf(file.type) == -1) {
        alert("アップロードできるファイルの形式はjpgです");
        $(this).val("");
        return false;
      }
      if (file.size > sizeLimit) {
        alert("ファイルサイズは2MB以下にしてください"); // エラーメッセージを表示
        file.value = ""; // inputの中身をリセット
        return; // 終了する
      }
      $(".filelabel").text("ファイルを再選択");
      var reader = new FileReader();
      reader.onload = function () {
        var img_src = reader.result;
        console.log(e.currentTarget);
        $("#prev").addClass("temp");
        document.getElementById("prev").innerHTML =
          "<img src='" + img_src + "' alt='none'>";
      };
      reader.readAsDataURL(file);
    });

    /*** 事業所画像アップロード ***/
    $(document).on(
      "change",
      '.org_info_ec input[name="eyecatch"]',
      function (e) {
        const sizeLimit = 2048 * 2048 * 1;

        $("#org_info_ec_prev img").remove();
        $("#org_info_ec_prev").removeClass("temp");

        var $this = $(this);
        var file = $this.prop("files")[0];
        //   var file = event.target.files[0];
        var permit_type = ["image/jpeg"]; //アップロードできるファイルタイプ
        if (file && permit_type.indexOf(file.type) == -1) {
          alert("アップロードできるファイルの形式はjpgです");
          $(this).val("");
          return false;
        }
        if (file.size > sizeLimit) {
          alert("ファイルサイズは2MB以下にしてください"); // エラーメッセージを表示
          file.value = ""; // inputの中身をリセット
          return; // 終了する
        }
        $(".org_info_ec .filelabel").text("ファイルを再選択");
        var reader = new FileReader();
        reader.onload = function () {
          var img_src = reader.result;
          console.log(e.currentTarget);
          $("#prev_eye").addClass("temp");
          document.getElementById("org_info_ec_prev").innerHTML =
            "<img src='" + img_src + "' alt='none'>";
        };
        reader.readAsDataURL(file);
      }
    );

    /*** 事業所ロゴアップロード ***/
    $(document).on(
      "change",
      '.logo_catch input[name="logo_path"]',
      function (e) {
        const sizeLimit = 2048 * 2048 * 1;

        $("#logo_prev img").remove();
        $("#logo_prev").removeClass("temp");
        var $this = $(this);
        var file = $this.prop("files")[0];
        //   var file = event.target.files[0];
        var permit_type = ["image/jpeg", "image/png", "image/gif"]; //アップロードできるファイルタイプ
        if (file && permit_type.indexOf(file.type) == -1) {
          alert("アップロードできるファイルの形式はjpg,png,gifです");
          $(this).val("");
          return false;
        }

        // ファイルサイズが制限以上
        if (file.size > sizeLimit) {
          alert("ファイルサイズは2MB以下にしてください"); // エラーメッセージを表示
          file.value = ""; // inputの中身をリセット
          return; // 終了する
        }

        $(".logo_catch .filelabel").text("ファイルを再選択");
        var reader = new FileReader();
        reader.onload = function () {
          var img_src = reader.result;
          console.log(e.currentTarget);
          $("#logo_prev").addClass("temp");
          document.getElementById("logo_prev").innerHTML =
            "<img src='" + img_src + "' alt='none'>";
        };
        reader.readAsDataURL(file);
      }
    );

    /*** ファビコン画像アップロード***/
    $(document).on(
      "change",
      '.favicon_catch input[name="favicon"]',
      function (e) {
        const sizeLimit = 2048 * 2048 * 1;

        $("#favicon_prev img").remove();
        $("#favicon_prev").removeClass("temp");
        var $this = $(this);
        var file = $this.prop("files")[0];
        //   var file = event.target.files[0];
        var permit_type = [
          "image/vnd.microsoft.icon",
          "image/png",
          "image/x-icon",
        ]; //アップロードできるファイルタイプ
        if (file && permit_type.indexOf(file.type) == -1) {
          alert("アップロードできるファイルの形式はico,pngです");
          $(this).val("");
          return false;
        }

        if (file.size > sizeLimit) {
          alert("ファイルサイズは2MB以下にしてください"); // エラーメッセージを表示
          file.value = ""; // inputの中身をリセット
          return; // 終了する
        }
        $(".favicon_catch .filelabel").text("ファイルを再選択");
        var reader = new FileReader();
        reader.onload = function () {
          var img_src = reader.result;
          console.log(e.currentTarget);
          $("#favicon_prev").addClass("temp");
          document.getElementById("favicon_prev").innerHTML =
            "<img src='" + img_src + "' alt='none'>";
        };
        reader.readAsDataURL(file);
      }
    );

    /***  保守レポートアップロード***/
    $(document).on("change", '.report_file input[name="report"]', function (e) {
      const sizeLimit = 2048 * 2048 * 1;

      $("#report_prev img").remove();
      $("#report_prev").removeClass("temp");
      var $this = $(this);
      var file = $this.prop("files")[0];
      //   var file = event.target.files[0];
      var permit_type = ["application/pdf"]; //アップロードできるファイルタイプ
      if (file && permit_type.indexOf(file.type) == -1) {
        alert("アップロードできるファイルの形式はpdfです");
        $(this).val("");
        return false;
      }

      if (file.size > sizeLimit) {
        alert("ファイルサイズは2MB以下にしてください"); // エラーメッセージを表示
        file.value = ""; // inputの中身をリセット
        return; // 終了する
      }
      $(".report_catch .filelabel").text("ファイルを再選択");
      $(this).siblings("#filename").text($(this).prop("files")[0].name);
    });

    $(document).ready(function () {
      $(document).on("click", ".item_apply_add_btn", function (e) {
        var count = 2;
        var $target = $("#itemlist_news");
        var $el = $(e.currentTarget);
        var $itembox = $("#itembox_apply");
        var htmlString = $("#item_apply_Template").html();
        $itembox.append(htmlString);
        $(".tooltip").tooltip({
          show: false,
          hide: false,
          tooltipClass: "tipinfo",
        });
        $(".tip-top").tooltip({
          position: {
            my: "center top",
            at: "center top",
          },
        });
        $(".tip-bottom").tooltip({
          position: {
            my: "center bottom",
            at: "center top",
          },
        });
        $(".tip-left").tooltip({
          position: {
            my: "left top",
            at: "right top",
          },
        });
        $(".tip-right").tooltip({
          position: {
            my: "right top",
            at: "left top",
          },
        });
      });
      $(document).on("click", ".item_apply_remove_btn", function (e) {
        var $el = $(e.currentTarget);
        var $row = $el.closest(".row_wrap,.new_item");
        $row.remove();
        return false;
      });
    });

    /*******アコーディオン（画像編集タイトルクリックで使用）*******/
    $(function () {
      $(document).on("click", ".acc_menu", function () {
        var $this = $(this);
        var html = "";
        $this.stop();
        $this.next(".acc_wrap").stop();
        $this.next(".acc_wrap").slideToggle("fast");
        $this.toggleClass("menuopen");
        $(".btn-area").append(html);
      });
    });

    $(function () {
      var ua = navigator.userAgent;
      if (
        (ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) &&
        ua.indexOf("Mobile") > 0
      ) {
        // スマートフォン用処理
        $(function () {
          // ボックスを表示する
          $(".button_search").on("click", function (e) {
            $(".megamenu_sp").fadeIn("fast");
            $(".megamenu_sp").addClass("open");
            $("#drawer_bg").addClass("show");
          });

          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu_sp").on("click", function (event) {
            event.stopPropagation();
          });

          // // ボックス外をクリックした際ボックスを非表示にする
          $(document).on("click", function (e) {
            if (
              e.target.className !== "megamenu_sp" &&
              e.target.className !== "button_search"
            ) {
              $(".megamenu_sp").fadeOut("fast");
              $(".megamenu_sp").removeClass("open");
              $("#drawer_bg").removeClass("show");
            }
          });
        });
      } else {
        // PC用処理
        /*******検索のinoutモーダル*******/
        /*https://teratail.com/questions/35924*/

        $(function () {
          // ボックスを表示する
          $(".form_dsn").on("click", function (e) {
            $(".megamenu").fadeIn("fast");
          });

          // ボックス内をクリックした際ボックスを非表示にしない
          $(".megamenu").on("click", function (event) {
            event.stopPropagation();
          });

          // ボックス外をクリックした際ボックスを非表示にする
          $(document).on("click", function (e) {
            if (
              e.target.className !== "megamenu" &&
              e.target.className !== "form_dsn"
            ) {
              if (
                e.target.className ==
                  "ui-datepicker-prev ui-corner-all ui-state-hover ui-datepicker-prev-hover" ||
                e.target.className == "ui-icon ui-icon-circle-triangle-w"
              ) {
                return false;
              }
              if (
                e.target.className ==
                  "ui-datepicker-next ui-corner-all ui-state-hover ui-datepicker-next-hover" ||
                e.target.className == "ui-icon ui-icon-circle-triangle-e"
              ) {
                return false;
              }

              $(".megamenu").fadeOut("fast");
            }
          });
        });
      }
    });

    /*********リンクにつける発火**** */
    $(function () {
      // $(".js-modal-open").on("click", function () {
      //   $(".modal").fadeIn();
      //   $("#confirmModal").addClass("open");
      //   return false;
      // });
    });
    /*********リンクにつける発火**** */
    // $(function () {
    //   $('.js-modal-open').on('click', function () {
    //     $('.modal').fadeIn();
    //     $("#discardModal").addClass("open");
    //     return false;
    //   });
    // });

    /******カレンダーの投稿用form2の消しボタン****** */
    $(function () {
      $("#close_btn-c").on("click", function () {
        $("#editModal").removeClass("open");
        return false;
      });
    });

    /*********通常のモーダルの消しボタン*********/
    $(function () {
      $("#close_btn").on("click", function () {
        $("#confirmModal").removeClass("open");
        return false;
      });
    });

    /********Ccスタッフ削除用*************/
    $(function () {
      $("#cancel_bk").on("click", function () {
        $("#confirmModal").removeClass("open");
        $(".modal_inner.confirm").parent().removeClass("open");
      });
    });

    // $(function(){
    //   // 制限サイズ
    //   const sizeLimit = 2048 * 2048 * 1;

    //   // input要素
    //   const fileInput = document.getElementById('ec_pls');
    //   console.log(fileInput);

    //   // changeイベントで呼び出す関数
    //   const handleFileSelect = () => {
    //     const files = fileInput.files;

    //     for (let i = 0; i < files.length; i++) {
    //       // ファイルサイズが制限以上
    //       if (files[i].size > sizeLimit) {
    //         alert('ファイルサイズは2MB以下にしてください'); // エラーメッセージを表示
    //         fileInput.value = ''; // inputの中身をリセット
    //         return; // 終了する
    //       }
    //     }
    //   }
    //   // フィールドの値が変更された時（≒ファイル選択時）に、handleFileSelectを発火
    //   fileInput.addEventListener('change', handleFileSelect);
    // });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
/*
@import "./assets/scss/main.scss";
*/

#g_header .g_header_list > li.has_child.openIs span:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
}
</style>

